    // text-stroke: 1px deeppink;
    // -webkit-text-stroke: 1px deeppink;


$text-color: #000;
$background-color: #232931;

$p-color: #4ecca3;
$s-color: #dd2700;
$t-color: #fbda00;

$white: #ffffff;
$black: #000000;

$light-grey: #e0e0e0;
$grey: #868686;
$dark-grey: #5d5d5d;

$menu-border-color: $grey;

$special-font: 'Raleway', sans-serif;

$header-height: 73px;

$spacer: 15px;

$section-spacer: $spacer * 4;

$background-fade: rgba(58, 58, 58, 0.37);

$radius: 5px;

$sidebar-width: 300px;

$tablet: 900px;
$large: 1050px;

// colors

$b1: #232931;
$b1h: #1c2127;
$b2: #393e46;
$b3: #151515;
$p1: #4ecca3;
$t1: #eeeeee;
$t2: #ababab;

// Shadows ---
@mixin shadow1 {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

@mixin shadow1hover {
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.14), 0 3px 11px -2px rgba(0, 0, 0, 0.12), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.14), 0 3px 11px -2px rgba(0, 0, 0, 0.12), 0 1px 7px 0 rgba(0, 0, 0, 0.2);
}

@mixin shadow2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}

@mixin shadow2hover {
  -webkit-box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.14), 0 1px 13px 0px rgba(0, 0, 0, 0.12), 0 2px 9px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.14), 0 1px 13px 0px rgba(0, 0, 0, 0.12), 0 2px 9px 0px rgba(0, 0, 0, 0.3)
}

// Blur ---
@mixin blur {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

// Button ---
@mixin button {
  background-color: $b1;
  box-shadow: 0 3px $b3;
  border-radius: 5px;
  transition: all .3s;
  color: $t1;
  padding: $spacer/2 $spacer*2;
  font-size: 18px;
  display: flex;
  align-items: center;

  > i {
    color: $p1;
    font-size: 25px;
    margin-right: $spacer/2;
  }

  &:hover {
    box-shadow: 0 6px #151515;
    transform: translatey(-3px);
    background-color: $b1h;
  }

  &:active {
    box-shadow: 0 1px #151515;
    transform: translatey(2px);
  }
}

// Fit Content ---
@mixin fit-content {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: -ms-fit-content;
  width: -o-fit-content;
  width: fit-content;
}

// Media queries ---
@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin large {
  @media (max-width: #{$large}) {
    @content;
  }
}

* {
  margin: 0;
}

html {
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  height: 100%;
  color: $text-color;
  display: flex;
  flex-direction: column;
  background-color: $b2;
  padding-top: 55px;
}

a {
  color: $black;
  text-decoration: none;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 0 $spacer * 2;
  align-items: center;
  margin-bottom: $section-spacer;
  flex-shrink: 0;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
}

header {
  position: fixed;
  width: calc(100% - 60px);
  top: 0;
  background-color: $b1;
  margin-bottom: 0 !important;
  z-index: 100000000;
  // box-shadow: 0 5px 10px #222930;

  .wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .h-logo {
      width: 100px;
      max-width: 100%;

      img {
        width: 100%;
        display: block;
        margin-bottom: -5px;
      }
    }

    .h-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: auto;
      
      a {
        font-size: 16px;
        padding: 5px 10px;
        margin-left: 10px;
        border-radius: 5px;
        color: $t2;
        transition: background-color .3s, color .3s;

        &:hover {
          background-color: $b2;
          color: $t1;
        }
      }
    }

    .h-social {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: auto;
      
      a {
        font-size: 30px;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        border-radius: 5px;
        color: $t2;
        transition: background-color .3s, color .3s;

        &:hover {
          background-color: $b2;
          color: $t1;
        }
      }
    }
  }
}

.section-title {
  font-size: 28px;
  color: $p1;
}

.section-description {
  font-size: 15px;
  color: $t1;
  margin-top: $spacer/2;
  margin-bottom: $spacer*2;
}

.owl-carousel .owl-stage-outer {
  padding-bottom: 3px;
  padding-top: 3px;
}

.items {
  // display: flex;

  .item {
    display: flex;
    flex-direction: column;
    background-color: $b1;
    border-radius: $radius;
    overflow: hidden;
    box-shadow: 0 3px $b3;
    transition: all .3s;

    &:hover {
      box-shadow: 0 6px #151515;
      transform: translatey(-3px);
      background-color: $b1h;
    }
  
    &:active {
      box-shadow: 0 1px #151515;
      transform: translatey(2px);
    }

    @include tablet {
      flex-direction: column;
    }

    .item-thumb {
      width: 100%;

      @include tablet {
        width: 100%;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    .item-details {
      margin: $spacer;
      display: flex;
      flex-direction: column;
      flex: 1;

      .item-info {
        color: $t1;
        font-size: 13px;
      }

      .item-title {
        font-size: 20px;
        font-weight: 400;
        color: $p1;
      }

      .item-description {
        font-size: 16px;
        margin-top: $spacer/2;
        color: $t2;
      }

      .item-actions {
        padding-top: $spacer;
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -$spacer;

        a {
          margin-right: $spacer;
          padding: $spacer/4 $spacer;
          color: $grey;
          border: 1px solid;
          text-transform: uppercase;
          margin-bottom: $spacer;

          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}

.page-intro {
  background-color: #232931;
  padding: 100px 30px;

  .page-thumb {
    display: none;
  }

  &.page-thumb-intro {
    padding-bottom: 0;
    overflow: hidden;

    .page-thumb {
      display: block;
      overflow: hidden;
      width: 100%;
      border-radius: 20px 20px 0 0;
      border: 5px solid $b2;
      border-bottom: 0;
      margin-top: $spacer*6;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .wrapper {

    h1 {
      font-size: 50px;
      text-align: center;
      color: $t1;
      margin-bottom: $spacer * 2;
    }

    p {
      text-align: center;
      color: $t2;
    }
  }
}

.post-width {
  max-width: 800px;
}

.project-section {
  
  > * {
    margin-bottom: $spacer*4;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.share-actions i.la-twitter {
  color: #1da1f2 !important;
}

.share-actions i.la-facebook {
  color: #3462c3 !important;
}

.share-actions i.la-reddit {
  color: #ff4500 !important;
}

.share-actions-wrapper {
  background-color: $b1;

  .share-actions {
    display: flex;
    align-items: center;

    a {
      display: flex;
      flex: 1;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $t1;
      padding-top: $spacer*2;
      padding-bottom: $spacer*2;
      transition: background-color .3s;
      font-size: 16px;

      &:hover {
        background-color: $b1h;
      }

      i {
        font-size: 50px;
        margin-bottom: 15px;
        background-color: $b1h;
        border-radius: 100%;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    color: $t1;
    font-weight: 400;
  }
}

.project-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-right: $spacer * 2;
    
    @include button;

    &:last-child {
      margin-right: 0;
    }
  }
}

.post-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $t1;

  > * {
    max-width: 800px;
    width: 100%;
    margin-bottom: $spacer*1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }
}

.owl-dot {
  outline: 0;
}

.owl-nav {
  position: absolute;
  top: -50px;
  bottom: 0;
  margin: auto 0 !important;
  height: 0;
  width: 100%;
}

.owl-nav button {
  background-color: $b2 !important;
  width: 50px;
  height: 50px;
  border-radius: 100% !important;
  color: $p1 !important;
  position: absolute;
  left: -40px;
  top: 0;
  bottom: 0;
  margin: auto $spacer !important;
  transition: opacity .5s, background-color .3s;
  border: 2px solid $p1 !important;
  outline: 0;
}

.owl-nav button:hover {
  background-color: $b1h !important;
}


.owl-nav button.disabled {
  opacity: 0 !important;
}

.owl-nav span {
  font-size: 35px;
  margin-top: -4px;
  display: block;
}

.owl-nav button.owl-next {
  left: unset;
  right: -40px;
}

.owl-dots {
  margin-top: $spacer*2 !important;
  display: flex;
  justify-content: center;
}

// .owl-item, .owl-stage {
//   display: flex;
// }

@media only screen and (max-width: 700px) {
  .project-actions {
    flex-direction: column;
  }

  .project-actions a {
    width: calc(100% - 60px) !important;
    margin-right: 0;
    margin-top: $spacer;
  }
}